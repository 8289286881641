<template>
	<div class="pages flex-center">
		<button
			class="homePage"
			:class="['end', { active: params.page == totalPage }]"
			@click="jump(1)"
		>
			首页
		</button>
		<button
			:class="[
				'prev',
				{ 'not-allowed': params.page <= 1, flipActive: params.page > 1 },
			]"
			@click="prevNext(-1)"
		>
			上一页
		</button>
		<a
			v-if="
				(params.page == 1 && page < params.page + 5) ||
				(params.page == totalPage && page > params.page - 5) ||
				(params.page == totalPage - 1 && page > params.page - 4) ||
				(params.page == totalPage - 2 && page > params.page - 3) ||
				(page > params.page - 2 && page < params.page + 4)
			"
			:class="['count', { active: params.page == page }]"
			href="javascript:;"
			v-for="(page, index) in totalPage"
			:key="index"
			@click="jump(page)"
			>{{ page }}</a
		>

		<button
			:class="[
				'next',
				{
					'not-allowed': params.page >= totalPage,
					flipActive: params.page < totalPage,
				},
			]"
			@click="prevNext(1)"
		>
			下一页
		</button>
		<div class="jump flex flex-center">
			前往
			<input type="text" v-model="pageNum" />
			页
		</div>
		<button class="jump-btn" @click="jump(pageNum * 1, 'go')">GO</button>
		<button
			:class="['end', { active: params.page == totalPage }]"
			@click="jump(totalPage)"
		>
			尾页
		</button>
		<span class="total">共{{ total }}条</span>
	</div>
</template>

<script>
export default {
	props: {
		total: {
			type: Number,
			default: 0,
		}, // 数据总数
		params: {
			type: Object,
			default: {
				page: 1,
				limit: 8,
			},
		},
	},
	data() {
		return {
			totalPage: 0, // 总页数
			pageNum: 1, // 跳转到指定页
		}
	},
	watch: {
		total(count) {
			this.totalPage = Math.ceil(count / this.params.limit)
		},
	},
	mounted() {
		this.totalPage = Math.ceil(this.total / this.params.limit)
	},
	methods: {
		prevNext(num) {
			window.scrollTo(0, 0)
			if (num < 0) {
				// 上一页
				if (this.params.page > 1) {
					this.params.page--
					this.jump(this.params.page)
				}
			} else {
				// 下一页
				if (this.params.page < this.totalPage) {
					this.params.page++
					this.jump(this.params.page)
				}
			}
		},
		jump(page, type) {
			// 翻页
			if (type == 'go') {
				// 跳转到指定页
				if (this.pageNum >= 1 && this.pageNum <= this.totalPage) {
					this.params.page = page
					this.$emit('jump', this.params)
				}
			} else {
				if (page > 0) {
					this.params.page = page
					this.$emit('jump', this.params)
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.pages {
	justify-content: center;
	// padding-right: 30px;
	color: #999;
	.homePage {
		margin-right: 10px;
	}
	.active {
		height: 25px !important;
		background: #e62129 !important;
		border: 1px solid #e62129 !important;
		color: #fff !important;
	}
	.flipActive {
		border: 1px solid #e62129 !important;
	}
	.not-allowed {
		cursor: not-allowed;
	}
	.total {
		font-size: 12px;
		margin-left: 10px;
	}
	.prev,
	.next {
		width: 58px;
		height: 27px;
		border: none;
		border-radius: 3px;
		background: #fff;
		border: 1px solid #ccc;
		color: #666666;
		font-size: 12px;
	}
	.prev {
	}
	.next {
	}
	.count {
		width: 30px;
		height: 26px;
		line-height: 26px;
		margin: 0 8px;
		border-radius: 3px;
		background: #fff;
		text-align: center;
		font-size: 16px;
		border: 1px solid #ccc;
		color: #999;
	}
	.jump {
		margin-left: 10px;
		font-size: 14px;
		input {
			width: 30px;
			max-height: 24px;
			line-height: 27px;
			background: #fff;
			border: 1px solid #ccc;
			color: #999;
			border-radius: 3px;
			margin: 0 6px;
			text-align: center;
			padding: 0;
		}
	}
	.jump-btn {
		width: 50px;
		height: 26px;
		line-height: 27px;
		border: none;
		border-radius: 3px;
		background: #fff;
		color: #e62129;
		border: 1px solid #e62129;
		margin-left: 10px;
		font-size: 12px;
	}
	.end {
		width: 58px;
		height: 26px !important;
		border: none;
		border-radius: 3px;
		// background: #E62129;
		border: 1px solid #e62129;
		margin-left: 10px;
		font-size: 12px;
		color: #e62129;
	}
}
</style>