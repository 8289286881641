<template>
	<div class="comment">
		<div class="degree_praise flex-center">
			<div class="favorable_comment flex-center">
				好评度
				<div class="num">{{ formData.praiseRate }}%</div>
			</div>
			<div class="allComment flex">
				<div
					class="list pointer"
					@click="commentBtn('')"
					:class="isGood === '' ? 'active' : ''"
				>
					全部评论({{ formData.sumEvaluateNum }})
				</div>
				<div
					@click="commentBtn(0)"
					:class="isGood === 0 ? 'active' : ''"
					class="list pointer"
				>
					好评({{ formData.okayEvaluateNum }})
				</div>
				<div
					@click="commentBtn(1)"
					:class="isGood == 1 ? 'active' : ''"
					class="list pointer"
				>
					中评({{ formData.middleEvaluateNum }})
				</div>
				<div
					@click="commentBtn(2)"
					:class="isGood == 2 ? 'active' : ''"
					class="list pointer"
				>
					差评({{ formData.differenceEvaluateNum }})
				</div>
			</div>
		</div>
		<div class="commen_content">
			<div
				class="commen_content_list flex"
				v-for="(item, index) in commentList"
				:key="index"
			>
				<div class="list_fl flex">
					<img
						class="commenUserImg"
						:src="item.avatar ? item.avatar : headImg"
						alt=""
					/>
					<div class="commenUserName nowrap">{{ item.nickName }}</div>
				</div>
				<div class="list_fr">
					<el-rate
						v-model="item.score"
						disabled
						text-color="#E1251B"
						:colors="colors"
						score-template="{value}"
					>
					</el-rate>
					<div class="comments_language">
						{{ item.content }}
					</div>
					<div class="goods_img_box flex">
						<img
							class="pointer"
							v-for="(items, indexs) in item.urls"
							:key="indexs"
							:src="items"
							alt=""
							@click="showBigImg(items)"
						/>
					</div>
					<!-- 查看大图 -->
					<div
						class="bigImages"
						v-for="items in item.urls"
						:key="items"
						v-show="items == bigImg && isHidden == true"
					>
						<img :src="bigImg" alt="" />
					</div>
					<div class="open_booking Between">
						<div class="open_booking_fl">{{ item.goodsTitle }}</div>
						<div class="open_booking_time">{{ item.gmtCreate }}</div>
					</div>
					<div
						class="replyBox"
						v-for="(itemx, indexx) in item.goodsEvaluateVOS"
						:key="indexx"
					>
						<div class="gej_reply">{{ itemx.nickName }} 回复：</div>
						<div class="reply">
							{{ itemx.content }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pageBox display" v-if="total >= 10">
			<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
		</div>
	</div>
</template>
<script>
import Pages from '@/components/pages.vue'
export default {
	props: {
		goodsId: {},
	},
	components: {
		Pages,
	},
	data() {
		return {
			isGood: '',
			headImg: require('@/assets/img/goodsDetails/headImg.png'),
			value: 3.7,
			colors: ['#E1251B', '#E1251B', '#E1251B'],
			params: {
				page: 1,
				limit: 10,
				goodsId: this.goodsId,
				type: '',
			},
			commentList: [],
			total: 0,
			formData: {},
			// 查看评论图放大
			isHidden: false,
			bigImg: '',
		}
	},
	mounted() {
		this.init()
		this.getsRateNum()
	},
	methods: {
		init() {
			this.api.findPageGoodsEvaluate(this.params).then((res) => {
				this.total = res.count
				res.data.map(function (item) {
					if (item.nickName != null && item.nickName != undefined) {
						let star = '' //存放名字中间的*
						//名字是两位的就取姓名首位+*
						if (item.nickName.length <= 2) {
							item.nickName = item.nickName.substring(0, 1) + ''
						} else {
							for (var i = 0; i < item.nickName.length - 2; i++) {
								star = star + '*'
							}
							item.nickName =
								item.nickName.substring(0, 1) +
								star +
								item.nickName.substring(
									item.nickName.length - 1,
									item.nickName.length
								)
						}
					}
				})
				this.commentList = res.data
			})
		},
		getsRateNum() {
			this.api.findEvaluateRateNum({ goodsId: this.goodsId }).then((res) => {
				this.formData = res.data
			})
		},
		commentBtn(type) {
			this.params.type = type
			this.isGood = type
			this.init()
		},
		//分页
		pageSkip(page) {
			this.params = page
			this.init()
		},
		// 点击查看大图
		showBigImg(url) {
			if (url && url != this.bigImg) {
				this.bigImg = url
				this.isHidden = true
			} else {
				this.bigImg = ''
				this.isHidden = false
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.comment {
	.active {
		color: #e1251b !important;
	}
	padding: 40px 22px 0px;
	box-sizing: border-box;
	.degree_praise {
		width: 100%;
		height: 40px;
		background: #f4f4f4;
		padding-left: 21px;
		box-sizing: border-box;
		.favorable_comment {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			.num {
				font-size: 30px;
				color: #e1251b;
				margin-left: 12px;
			}
		}
		.allComment {
			margin-left: 52px;
			.list {
				font-size: 12px;
				color: #333333;
				margin-right: 30px;
			}
		}
	}
	.commen_content {
		.commen_content_list {
			width: 100%;
			padding: 30px 39px 27px 43px;
			box-sizing: border-box;
			border-bottom: 1px solid #dedede;
			.list_fl {
				.commenUserImg {
					width: 45px;
					height: 45px;
					border-radius: 50%;
				}
				.commenUserName {
					width: 20px;
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					margin-top: 12px;
					margin-left: 12px;
				}
			}
			.list_fr {
				margin-left: 69px;
				.comments_language {
					width: 617px;
					font-size: 14px;
					color: #333333;
					line-height: 22px;
					margin-top: 10px;
				}
				.goods_img_box {
					margin-top: 21px;
					img {
						width: 60px;
						height: 60px;
						margin-right: 8px;
					}
				}
				.open_booking {
					width: 735px;
					font-size: 12px;
					color: #888888;
					margin-top: 23px;
				}
				.replyBox {
					width: 605px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #e1251b;
					line-height: 22px;
					margin-top: 26px;
				}
				.bigImages {
					margin-top: 10px;
					max-width: 400px;
				}
			}
		}
	}
	.pageBox {
		width: 100%;
		height: 82px;
	}
}
</style>