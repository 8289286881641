<template>
    <div class="goodsDetailsBox">
        <Classification :types="1" :navData="$store.state.navData" :sidebarData="$store.state.sidebarData">
        </Classification>
        <div class="goodsDetails">
            <div class="title pointer" @click="toHome">
                首页 > {{ goodsData.productName }}
            </div>
            <div class="goodsContent flex">
                <div class="goodsContent_fl">
                    <!-- <div class="bigImgBox display">
						<img class="bigImg" :src="coverUrl" alt="" />
					</div> -->
                    <div class="magnifyingGlass flex">
                        <div class="left_div display">
                            <div class="bigImgBox display">
                                <img class="bigImgs" :src="coverUrl" alt="" />
                                <!-- 鼠标层罩 -->
                                <div v-show="topShow" class="top" :style="topStyle"></div>
                                <!-- 最顶层覆盖了整个原图空间的透明层罩 -->
                                <div class="maskTop" @mouseenter="enterHandler" @mousemove="moveHandler"
                                    @mouseout="outHandler"></div>
                            </div>
                        </div>
                        <div v-show="rShow" class="right">
                            <img :style="r_img" class="rightImg" :src="coverUrl" alt="" />
                        </div>
                    </div>
                    <div class="swiper_box">
                        <swiper class="swiper" ref="swiper" :options="swiperOp">
                            <swiper-slide v-for="(item, index) in goodsData.urls" :key="index">
                                <div class="goodsBox display" @click="goodsImgBtn(item)">
                                    <div class="goodsImg pointer">
                                        <img :src="item" alt="" />
                                    </div>
                                </div>
                            </swiper-slide>

                            <div class="swiper-button-prev" slot="button-prev">
                                <img class="icon_left_right" src="../../assets/img/goodsDetails/goods_fl.png" alt="" />
                            </div>
                            <div class="swiper-button-next" slot="button-next">
                                <img class="icon_left_right" src="../../assets/img/goodsDetails/goods_fr.png" alt="" />
                            </div>
                        </swiper>
                        <div class="collect_PlaceOrder flex">
                            <div class="list flex-center pointer" @click="collectBnt(goodsData.isCollect)">
                                <img class="icon_collect" :src="goodsData.isCollect == 0 ? collect_n : collect_y" alt="" />
                                {{ goodsData.isCollect == 0 ? '收藏' : '已收藏' }}
                            </div>
                            <!-- <div class="list flex-center pointer" @click="codeBtn" @mouseenter="codeEnter()" @mouseleave="codeLeave()">
                                <img class="icon_PlaceOrder" src="@/assets/img/goodsDetails/PlaceOrder.png" alt="">
                                手机下单
                                <div class="code_box display" v-if="isCode == true">
                                    <img src="@/assets/img/goodsDetails/codeImg.png" alt="">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="goodsContent_fr">
                    <div class="goods_name">{{ goodsData.productName }}</div>
                    <div class="preferential_price_box">
                        <div class="price_list flex">
                            <div class="label">售 &nbsp &nbsp&nbsp 价:</div>
                            <div class="price_box flex">
                                <div class="price_box_div">￥</div>
                                <div class="price">
                                    <span v-if="minprice">{{ Number(minprice).toFixed(2) }}</span>
                                    <span v-else>{{
                                                                            Number(goodsData.goodsPrice).toFixed(2)
                                                                            }}</span>
                                </div>
                                <div class="unit price_box_div">/{{ goodsData.unitName }}</div>
                            </div>
                        </div>
                        <div class="price_list flex coupons">
                            <div class="label">优 惠 券：</div>
                            <div class="coupons_box flex">
                                <div class="coupons_list display" v-for="item in couponList" :key="item.id"
                                    @click="toPlazaIndex">
                                    满{{ item.consumptionPrice }}减{{ item.couponMoney }}
                                    <img class="icon_fl" src="@/assets/img/goodsDetails/fl.png" alt="" />
                                    <img class="icon_fr" src="@/assets/img/goodsDetails/fr.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="specifications">
                        <div class="list flex" v-for="(attr, index) in process_attribute" :key="index">
                            <div class="table">{{ attr.name }}：</div>

                            <div class="specifications_list flex flex-wrap">
                                <el-button class="naem row" v-for="(item, index2) in attr.item" :key="index2"
                                    :type="item.actived ? 'primary' : ''" :disabled="item.disabled"
                                    @click="skuClick(index, index2)">{{ item.name }}</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="payType flex">
                        <div class="label">支付方式：</div>
                        <div class="payType_list flex">
                            <div class="list flex-center">
                                <img class="wx_img" src="@/assets/img/goodsDetails/wx.png" alt="" />
                                微信
                            </div>
                            <div class="list flex-center">
                                <img class="zfb_img" src="@/assets/img/goodsDetails/zfb.png" alt="" />
                                支付宝
                            </div>
                            <div class="list flex-center">
                                <img class="yl_img" src="@/assets/img/goodsDetails/yl.png" alt="" />
                                在线支付
                            </div>
                        </div>
                    </div>
                    <div class="payType flex safeguard">
                        <div class="label">服务保障：</div>
                        <div class="payType_list flex">
                            <div class="list flex-center">
                                <img class="safeguard_img" src="@/assets/img/goodsDetails/zp.png" alt="" />
                                正品保障
                            </div>
                            <div class="list flex-center">
                                <img class="safeguard_img" src="@/assets/img/goodsDetails/fp.png" alt="" />
                                发票保障
                            </div>
                            <div class="list flex-center">
                                <img class="safeguard_img" src="@/assets/img/goodsDetails/by.png" alt="" />
                                满300包邮
                            </div>
                            <div class="list flex-center pointer" @click="service">
                                <img class="safeguard_img" src="@/assets/img/phone1.png" alt="" />
                                联系客服
                            </div>
                        </div>
                    </div>
                    <div class="goods_num_box flex-center">
                        <div class="label">采 &nbsp;购&nbsp;量：</div>
                        <div class="num_box flex">
                            <el-input-number v-model="goodsNum" size="small" @change="handleChange" :min="1"
                                :max="Number(stock)" label="描述文字"></el-input-number>
                        </div>
                    </div>
                    <div class="btnBox flex">
                        <div class="btn display buyBtn pointer" @click="buyBtn">
                            立即购买
                        </div>
                        <div class="btn display addCart pointer" @click="addCart">
                            加入购物车
                        </div>
                    </div>
                </div>
            </div>
            <div class="recommend_box" v-if="navBar.length != 0">
                <!-- <div class="navBar flex-center sidebar">
					<div class="recommend_div display nowrap">相关推荐</div>
					<div
						class="list display pointer nowrap"
						:class="navBarIndex == index ? 'active' : ''"
						v-for="(item, index) in navBar"
						:key="index"
						@click="onChange(item, index)"
					>
						<div class="name">{{ item.catName }}</div>
						<div class="line" v-if="navBarIndex == index"></div>
					</div>
				</div> -->
                <div class="navBar flex">
                    <div class="recommend_div display nowrap">相关推荐</div>
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in navBar" :key="index">
                            <div class="list display pointer nowrap" :class="navBarIndex == index ? 'active' : ''"
                                @click="onChange(item, index)">
                                <div class="name">{{ item.catName }}</div>
                                <div class="line" v-if="navBarIndex == index"></div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in recommendList" :key="index">
                            <div class="goodsBox flex column pointer" @click="changeBtn(item.id)">
                                <div class="goodsImg display">
                                    <img :src="item.coverUrl" alt="" />
                                </div>
                                <div class="goodsName astrict">{{ item.productName }}</div>
                                <div class="goodsPrice_box flex-center" @click.stop="chckedBtn(item, index)">
                                    <input class="checks pointer" :disabled="item.storeCount > 1 ? false : true"
                                        v-model="item.isChecked" type="checkbox" />
                                    <div class="price">￥{{ Number(item.price).toFixed(2) }}</div>
                                </div>
                            </div>
                        </swiper-slide>

                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="../../assets/img/left.png" alt="" />
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="../../assets/img/right.png" alt="" />
                        </div>
                    </swiper>
                </div>
            </div>
            <div class="details_box flex">
                <div class="fl sales_volume">
                    <div class="ranking">销量排行榜</div>
                    <div class="goods_ranking_list pointer" v-for="(item, index) in goodsData.salesGoodsVOS" :key="index"
                        @click="changeBtn(item.id)">
                        <div class="goods_img display">
                            <img :src="item.coverUrl" alt="" />
                        </div>
                        <div class="goodsName astrict">{{ item.productName }}</div>
                        <div class="goodsPrice">￥{{ Number(item.price).toFixed(2) }}</div>
                    </div>
                </div>
                <div class="fr">
                    <div class="navBerBox flex-center">
                        <div class="list display pointer" :class="navBerActive == index ? 'navActive' : ''"
                            v-for="(item, index) in navBer" :key="index" @click="commentBtn(index)">
                            {{ item.name }}
                            <span v-if="item.name == '商品评价'">({{ goodsData.commentNumber }}条)</span>
                            <div class="line" v-if="navBerActive == index"></div>
                        </div>
                    </div>
                    <div class="goods_details_content" v-if="navBerActive == 0">
                        <div v-html="goodsData.productDetails"></div>
                    </div>
                    <div class="comment_box" v-else>
                        <Comment :goodsId="params.id"></Comment>
                    </div>
                </div>
            </div>
        </div>
        <div class="goods_sidebar">
            <div class="list display pointer" v-for="(item, index) in sidebarData" :key="index"
                @mouseover="mouseOver(item.url, index)" @mouseleave="mouseLeave" @click="sidebarBtn(item.id)">
                <img :src="item.url" alt="" />
                <div class="hintBox display nowrap pointer"
                    v-if="sidebarIndex == index && sidebarIndex != 4 && sidebarIndex != 2">
                    <img :src="sidebarUrl" alt="" />
                    <span v-if="sidebarIndex == 0">400-698-6665 </span>
                    <span v-if="sidebarIndex == 1">我的购物车</span>
                    <span v-if="sidebarIndex == 3">意见反馈</span>
                    <span></span>
                </div>
                <div class="qrCode_box display pointer column" v-if="sidebarIndex == index && sidebarIndex == 2">
                    <img src="@/assets/img/code.png" alt="" />
                    <span>手机下单</span>
                    <span>筑手建筑防护超市APP</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Classification from '@/components/classification.vue'
import Comment from './components/comment.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    components: {
        Swiper,
        SwiperSlide,
        Classification,
        Comment,
    },
    data() {
        return {
            navBarIndex: 0,
            sidebarIndex: null,
            sidebarUrl: '',
            coverUrl: '',
            isCode: false,
            collect_n: require('@/assets/img/goodsDetails/collect_n.png'),
            collect_y: require('@/assets/img/goodsDetails/collect_y.png'),
            goodsNum: 1,
            swiperOp: {
                slidesPerView: 7,
                spaceBetween: 20,
                pagination: {
                    el: '.swiper-pagination', //与slot="pagination"处 class 一致
                    clickable: true, //轮播按钮支持点击
                },
            },
            swiperOption: {
                slidesPerView: 5,
                observer: true,
                observeParents: true,
                spaceBetween: 16.5,
                direction: 'horizontal',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 960 ? 'vertical' : 'horizontal'
                        )
                    },
                },
            },
            swiperOptions: {
                slidesPerView: 6,
                speed: 1000, //匀速时间
                observer: true,
                observeParents: true,
                spaceBetween: 0,
                direction: 'horizontal',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 960 ? 'vertical' : 'horizontal'
                        )
                    },
                },
            },
            navBerActive: 0,
            navBer: [
                {
                    id: 1,
                    name: '商品详情',
                },
                {
                    id: 2,
                    name: '商品评价',
                },
            ],
            params: {
                id: '',
                token: this.$store.state.userData.token,
            },
            goodsData: {},
            separator: ';',
            attribute: [],
            sku: [],
            process_attribute: [],
            process_sku: [],
            // 当前选中 sku 的库存及价格区间
            stock: '',
            skuId: '',
            minprice: 0,
            maxprice: 0,
            // 优惠券列表=最多三条
            couponList: [],
            sidebarData: [
                {
                    id: 1,
                    url: require('../../assets/img/goodsDetails/sidebar2.png'),
                },
                {
                    id: 2,
                    url: require('../../assets/img/goodsDetails/cart.png'),
                },
                {
                    id: 3,
                    url: require('../../assets/img/goodsDetails/sidebar4.png'),
                },
                {
                    id: 4,
                    url: require('../../assets/img/goodsDetails/sidebar5.png'),
                },
                {
                    id: 5,
                    url: require('../../assets/img/goodsDetails/sidebar6.png'),
                },
            ],
            recommendList: [], //相关推荐
            navBar: [],
            topStyle: { transform: '' },
            r_img: {},
            topShow: false,
            rShow: false,
        }
    },
    // 监听路由变化
    watch: {
        $route: 'urlName',
    },
    mounted() {
        this.params.id = this.$route.query.id
        this.navBerActive = sessionStorage.getItem('navBerActive') || 0
        this.init()
        this.getGoodsDetails()
    },
    beforeDestroy() {
        sessionStorage.removeItem('navBerActive')
    },
    methods: {
        //联系客服
        service() {
            document.getElementById('layui-kflayer19911116').style.display = 'block'
        },
        // 监听url参数变化
        urlName() {
            this.init()
        },
        //获取商品详情
        init() {
            // 对 attribute 数据进行加工，并存入 process_attribute 中
            this.api.findGoodsDetails(this.params).then((res) => {
                this.goodsData = {}
                this.process_attribute = []

                res.data.salesGoodsVOS.forEach((item, index) => {
                    if (this.$route.query.id == item.id) {
                        res.data.salesGoodsVOS.splice(index, 1)
                    }
                })
                this.goodsData = res.data

                if (this.goodsData.urls != null) {
                    this.coverUrl = this.goodsData.urls[0]
                }
                this.attribute = res.data.goodsAttributeVOS
                this.sku = res.data.goodsSkuPriceVOS

                this.attribute.map((attr) => {
                    let temp = {
                        name: attr.name,
                    }
                    temp.item = attr.items.map((item) => {
                        return {
                            name: item,
                            actived: false,
                            disabled: false,
                        }
                    })
                    temp.item[0].actived = true
                    this.process_attribute.push(temp)
                })
                // 对 sku 数据进行加工，并存入 process_sku 中
                this.sku.map((v) => {
                    this.$set(v, 'sku', v.goodsTitle)
                    this.$set(v, 'stock', v.storeCount)
                    var combArr = this.arrayCombine(v.sku.split(this.separator))
                    for (var j = 0; j < combArr.length; j++) {
                        var key = combArr[j].join(this.separator)
                        if (this.process_sku[key]) {
                            // 库存累加，价格添加进数组
                            this.process_sku[key].stock += v.stock
                            this.process_sku[key].prices.push(v.price)
                        } else {
                            this.process_sku[key] = {
                                stock: v.stock,
                                prices: [v.price],
                            }
                        }
                    }
                })
                // 更新数据视图
                this.process_sku = Object.assign({}, this.process_sku)
                this.skuCheck()
            })
            //  获取优惠券列表=截取三条
            if (this.$store.state.userData.token) {
                this.api
                    .queryByUserId({ token: this.$store.state.userData.token, status: 0 })
                    .then((res) => {
                        let dataList = res.data
                        if (dataList.length <= 3) {
                            this.couponList = dataList
                        } else {
                            this.couponList = dataList.slice(0, 3)
                        }
                    })
            }
        },

        //获取推荐商品
        getGoodsDetails() {
            let params = {
                goodsId: this.$route.query.id,
                token: this.$store.state.userData.toke,
            }
            this.api.findNewRecommendGoods(params).then((res) => {
                if (res.code == 0) {
                    let data = JSON.parse(JSON.stringify(res.data))
                    let dataArr = data.filter((item) => {
                        if (item != null) {
                            return item
                        }
                    })
                    if (dataArr.length != 0) {
                        // this.navBar = dataArr.slice(0, 5)
                        this.navBar = dataArr
                        this.recommendList = dataArr[0].goodsListVOS
                    }
                    // dataArr[0].goodsListVOS.forEach((item) => {
                    // 	this.$set(item, 'isChcked', false)
                    // })
                }
            })
        },
        changeBtn(id) {
            let routeData = this.$router.resolve({
                path: '/mall/goodsDetails',
                query: {
                    id: id,
                },
            })
            window.open(routeData.href, '_blank')
        },

        arrayCombine(targetArr) {
            var resultArr = []
            for (var n = 0; n <= targetArr.length; n++) {
                var flagArrs = this.getFlagArrs(targetArr.length, n)
                while (flagArrs.length) {
                    var flagArr = flagArrs.shift()
                    var combArr = Array(targetArr.length)
                    for (var i = 0; i < targetArr.length; i++) {
                        if (flagArr[i]) {
                            combArr[i] = targetArr[i]
                        }
                    }
                    resultArr.push(combArr)
                }
            }
            return resultArr
        },
        getFlagArrs(m, n) {
            var flagArrs = [],
                flagArr = [],
                isEnd = false
            for (var i = 0; i < m; i++) {
                flagArr[i] = i < n ? 1 : 0
            }
            flagArrs.push(flagArr.concat())
            // 当n不等于0并且m大于n的时候进入
            if (n && m > n) {
                while (!isEnd) {
                    var leftCnt = 0
                    for (var i = 0; i < m - 1; i++) {
                        if (flagArr[i] == 1 && flagArr[i + 1] == 0) {
                            for (var j = 0; j < i; j++) {
                                flagArr[j] = j < leftCnt ? 1 : 0
                            }
                            flagArr[i] = 0
                            flagArr[i + 1] = 1
                            var aTmp = flagArr.concat()
                            flagArrs.push(aTmp)
                            if (aTmp.slice(-n).join('').indexOf('0') == -1) {
                                isEnd = true
                            }
                            break
                        }
                        flagArr[i] == 1 && leftCnt++
                    }
                }
            }
            return flagArrs
        },
        skuClick(key1, key2) {
            this.goodsNum = 1
            if (!this.process_attribute[key1].item[key2].disabled) {
                this.process_attribute[key1].item.map((item, index) => {
                    item.actived = index == key2 ? !item.actived : false
                })
                this.skuCheck()
                this.getStockPrice()
            }
        },
        skuCheck() {
            let sku = []
            this.process_attribute.map((attr) => {
                let name = ''
                attr.item.map((item) => {
                    if (item.actived) {
                        name = item.name
                    }
                })
                sku.push(name)
            })
            this.stock = this.process_sku[sku.join(this.separator)].stock
            this.minprice = Math.min.apply(
                Math,
                this.process_sku[sku.join(this.separator)].prices
            )
            this.maxprice = Math.max.apply(
                Math,
                this.process_sku[sku.join(this.separator)].prices
            )
        },
        getStockPrice() {
            this.process_attribute.map((attr) => {
                attr.item.map((item) => {
                    item.disabled = false
                })
            })
            let count = 0
            let i = 0
            this.process_attribute.map((attr, index) => {
                let flag = false
                attr.item.map((item) => {
                    if (item.actived) {
                        flag = true
                    }
                })
                if (!flag) {
                    count += 1
                    i = index
                }
            })
            // 当只有一组规格没选时
            if (count == 1) {
                this.process_attribute[i].item.map((item) => {
                    let sku = []
                    let text = item.name
                    this.process_attribute.map((attr, index) => {
                        if (index != i) {
                            attr.item.map((item2) => {
                                if (item2.actived) {
                                    sku.push(item2.name)
                                }
                            })
                        } else {
                            sku.push(text)
                        }
                    })
                    if (this.process_sku[sku.join(this.separator)].stock == 0) {
                        item.disabled = true
                    }
                })
            }
            // 当所有规格都有选时
            if (count == 0) {
                this.process_attribute.map((attr, index) => {
                    let i = index
                    this.process_attribute[index].item.map((item) => {
                        if (!item.actived) {
                            let sku = []
                            let text = item.name
                            this.process_attribute.map((list, index) => {
                                if (index != i) {
                                    list.item.map((item2) => {
                                        if (item2.actived) {
                                            sku.push(item2.name)
                                        }
                                    })
                                } else {
                                    sku.push(text)
                                }
                            })
                            if (this.process_sku[sku.join(this.separator)].stock == 0) {
                                item.disabled = true
                            }
                        }
                    })
                })
            }
        },

        //商品数量
        handleChange(value) {
            if (value >= this.stock) {
            }
        },
        // 商品详情-评论切换
        commentBtn(index) {
            this.navBerActive = index
            sessionStorage.setItem('navBerActive', this.navBerActive)
        },
        //收藏
        collectBnt(type) {
            let params = {
                productId: this.$route.query.id,
                token: this.$store.state.userData.token,
            }
            if (type == 0) {
                this.api.addCollec(params).then((res) => {
                    if (res.code == 0) {
                        this.$message.success('收藏成功！')
                        this.init()
                    } else {
                        if (res.msg == 'token不能为空') {
                            this.$router.push({
                                path: '/login',
                                query: {
                                    isLogin: false,
                                },
                            })
                        } else {
                            this.$message.error(res.msg)
                        }
                    }
                })
            } else {
                this.api.delCollect(params).then((res) => {
                    if (res.code == 0) {
                        this.$message.success('取消收藏成功！')
                        this.init()
                    } else {
                        if (res.msg == 'token不能为空') {
                            this.$router.push({
                                path: '/login',
                                query: {
                                    isLogin: 'no',
                                },
                            })
                        } else {
                            this.$message.error(res.msg)
                        }
                    }
                })
            }
        },
        //手机下单二维码显示
        codeBtn() {
            this.isCode = !this.isCode
        },
        //手机下单二维码显示
        codeEnter() {
            this.isCode = true
        },
        //手机下单二维码隐藏
        codeLeave() {
            this.isCode = false
        },
        // 加入购物车
        addCart() {
            let sku = []
            //储存未登录时的购物车信息
            let cartData = []
            let skuData = []
            let cartObj = {
                id: this.goodsData.id,
                goodsId: this.goodsData.id,
                skuPriceId: '',
                skuTitle: '',
                unitPrice: this.minprice,
                num: this.goodsNum,
                totalPrice: this.minprice * this.goodsNum,
                productName: this.goodsData.productName,
                coverUrl: this.goodsData.coverUrl,
                onShelf: 1,
            }

            if (JSON.parse(localStorage.getItem('cartData'))) {
                cartData = JSON.parse(localStorage.getItem('cartData')) || []
            }
            if (JSON.parse(localStorage.getItem('skuData'))) {
                skuData = JSON.parse(localStorage.getItem('skuData')) || []
            }
            let isSelectSKU = this.process_attribute.every((attr) => {
                let filter = attr.item.filter((v) => v.actived)
                if (filter.length != 0) {
                    sku.push(filter[0].name)
                }
                return filter.length != 0
            })
            let skus = sku.join(this.separator)
            if (isSelectSKU) {
                let obj = {}
                let params = {
                    token: this.$store.state.userData.token,
                    shopCartGoodsParamDTOS: [],
                }

                // 获取选中规格SKU
                this.sku.forEach((item) => {
                    if (item.goodsTitle == skus) {
                        obj = {
                            num: this.goodsNum,
                            goodsId: Number(this.$route.query.id),
                            skuPriceId: item.id,
                        }
                        params.shopCartGoodsParamDTOS.push(obj)
                        cartObj.skuPriceId = item.id
                        cartObj.skuTitle = item.goodsTitle
                    }
                })
                //获取选中的推荐商品
                let goodsArr = []
                goodsArr.push(cartObj)
                let goodsObj = {}
                this.navBar.forEach((item) => {
                    item.goodsListVOS.forEach((goods) => {
                        if (goods.isChecked == true) {
                            goodsObj = {
                                goodsId: goods.id,
                                skuPriceId: goods.skuId,
                                num: 1,
                            }
                            let datas = {
                                id: goods.id,
                                goodsId: goods.id,
                                skuPriceId: goods.skuId,
                                skuTitle: goods.goodsTitle,
                                unitPrice: goods.price,
                                num: 1,
                                totalPrice: goods.price,
                                productName: goods.productName,
                                coverUrl: goods.coverUrl,
                                onShelf: 1,
                            }
                            goodsArr.push(datas)
                            params.shopCartGoodsParamDTOS.push(goodsObj)
                        }
                    })
                })
                //已登陆
                if (this.$store.state.userData.token) {
                    this.api.addShopCartGoods(params).then((res) => {
                        if (res.code == 0) {
                            let addCartData = {
                                page: 1,
                                limit: 10,
                                token: this.$store.state.userData.token,
                            }
                            this.api.findAllPageShopCartGoods(addCartData).then((res) => {
                                if (res.code == 0) {
                                    this.$store.commit('getcartData', res.data)
                                    this.$store.commit('getcartNum', res.count)
                                }
                            })
                            this.$message.success('加入成功')
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    //未登录
                    const cartDataList = cartData.map((item) => {
                        return item.skuPriceId
                    })
                    if (cartData.length != 0) {
                        goodsArr.forEach((goodsItem) => {
                            cartData.forEach((item) => {
                                if (!cartDataList.includes(goodsItem.skuPriceId)) {
                                    cartData.push(goodsItem)
                                }
                                if (item.skuPriceId == goodsItem.skuPriceId) {
                                    item.num += goodsItem.num
                                    item.totalPrice = item.unitPrice * item.num
                                }
                            })
                        })
                        const map = new Map()
                        const newArr = cartData.filter(
                            (key) => !map.has(key.skuPriceId) && map.set(key.skuPriceId, 1)
                        )
                        cartData = newArr
                        let skuArr = []
                        let skuObj = {}
                        cartData.forEach((item) => {
                            skuObj = {
                                num: item.num,
                                goodsId: item.id,
                                skuPriceId: item.skuPriceId,
                            }
                            skuArr.push(skuObj)
                        })
                        skuData = skuArr
                    } else {
                        cartData = goodsArr
                        let skuObj = {}
                        let arr = []
                        goodsArr.forEach((res) => {
                            skuObj = {
                                num: res.num,
                                goodsId: res.id,
                                skuPriceId: res.skuPriceId,
                            }
                            arr.push(skuObj)
                        })
                        skuData = arr
                    }
                    this.$message.success('加入成功')
                    localStorage.setItem('cartData', JSON.stringify(cartData))
                    this.$store.commit('getcartData', cartData)
                    this.$store.commit('getcartNum', cartData.length)
                    localStorage.setItem('skuData', JSON.stringify(skuData))
                }
            } else {
                this.$message.error('请选择完整商品属性')
            }
        },
        // 立即购买
        buyBtn() {
            let sku = []
            //判断有没有选规格
            let isSelectSKU = this.process_attribute.every((attr) => {
                let filter = attr.item.filter((v) => v.actived)
                if (filter.length != 0) {
                    sku.push(filter[0].name)
                }
                return filter.length != 0
            })
            if (isSelectSKU) {
                let params = {
                    goodsId: Number(this.$route.query.id),
                    skuPriceId: '',
                    goodsNum: this.goodsNum,
                }
                // 选中的规格作对比拿到对应的skuId
                let skus = sku.join(this.separator)
                // 获取选中规格SKU
                this.sku.forEach((item) => {
                    if (item.goodsTitle == skus) {
                        params.skuPriceId = item.id
                    }
                })

                let goodsArr = []
                goodsArr.unshift(params)
                if (this.$store.state.userData.token) {
                    let goodsObj = {}
                    this.navBar.forEach((item) => {
                        item.goodsListVOS.forEach((goods) => {
                            if (goods.isChecked == true) {
                                goodsObj = {
                                    goodsId: goods.id,
                                    skuPriceId: goods.skuId,
                                    goodsNum: 1,
                                }
                                goodsArr.push(goodsObj)
                            }
                        })
                    })
                    let goodsData = {
                        goodsDetailsConfirmOrderItemParamDTOS: goodsArr,
                    }
                    // 已登录
                    sessionStorage.setItem('confirmOrder', JSON.stringify(goodsData))
                    this.$router.push({
                        path: '/cart/placeOrder',
                        query: {
                            type: 'goods',
                        },
                    })
                } else {
                    this.$message.warning('请登录！')
                    // 未登录
                    this.$router.push({
                        path: '/login',
                        query: {
                            isLogin: 'no',
                        },
                    })
                }
            } else {
                this.$message.error('请选择完整商品属性')
            }
        },
        //点击商品图片
        goodsImgBtn(url) {
            this.coverUrl = url
            // this.goodsData.coverUrl = url
        },
        // 商品详情-优惠券点击-入领券广场
        toPlazaIndex() {
            let routeData = this.$router.resolve({ path: '/plaza/index' })
            window.open(routeData.href, '_blank')
        },
        // 移入
        mouseOver(url, index) {
            this.sidebarUrl = url
            this.sidebarIndex = index
        },
        // 移出
        mouseLeave() {
            this.sidebarIndex = null
        },
        sidebarBtn(id) {
            if (id == 2) {
                let routeData = this.$router.resolve({ path: '/cart/index' })
                window.open(routeData.href, '_blank')
            } else if (id == 4) {
                sessionStorage.removeItem('broadside')
                let routeData = this.$router.resolve({
                    path: '/person/index',
                    query: {
                        type: 19,
                    },
                })
                window.open(routeData.href, '_blank')
            } else if (id == 5) {
                let top = document.documentElement.scrollTop || document.body.scrollTop
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop =
                        document.documentElement.scrollTop =
                        top -=
                        50
                    if (top <= 0) {
                        clearInterval(timeTop)
                    }
                }, 10)
            }
        },
        toHome() {
            this.$router.push('/')
        },
        //点击推荐商品导航
        onChange(item, index) {
            this.navBarIndex = index

            this.recommendList = item.goodsListVOS
        },
        chckedBtn(item, index) {
            if (item.storeCount > 1) {
                if (typeof item.isChecked == 'undefined') {
                    this.$set(item, 'isChecked', true)
                } else {
                    item.isChecked = !item.isChecked
                }
            } else {
                this.$message.error('该商品库存不足')
            }
        },
        // 鼠标进入原图空间函数
        enterHandler() {
            // 层罩及放大空间的显示
            this.topShow = true
            this.rShow = true
        },
        // 鼠标移动函数
        moveHandler(event) {
            // 鼠标的坐标位置
            let x = event.offsetX
            let y = event.offsetY
            // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
            let topX = x - 100 < 0 ? 0 : x - 100
            let topY = y - 100 < 0 ? 0 : y - 100
            // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
            if (topX > 230) {
                topX = 230
            }
            if (topY > 230) {
                topY = 230
            }
            // 通过 transform 进行移动控制
            this.topStyle.transform = `translate(${topX}px,${topY}px)`
            this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`
        },
        // 鼠标移出函数
        outHandler() {
            // 控制层罩与放大空间的隐藏
            this.topShow = false
            this.rShow = false
        },
    },
}
</script>
<style lang="scss" scoped>
.goodsDetails {
    width: 1200px;
    margin: 0 auto;

    .title {
        height: 48px;
        line-height: 48px;
        padding-left: 10px;
        font-size: 12px;
        color: #666666;
    }

    .goodsContent {
        width: 1200px;
        background: white;
        padding: 20px;
        padding-bottom: 60px;
        box-sizing: border-box;

        .goodsContent_fl {
            width: 430px;

            .bigImgBox {
                width: 430px;
                height: 430px;
                border: 1px solid #e7e7e7;

                .bigImg {
                    width: 380px;
                    height: 380px;
                }
            }

            .collect_PlaceOrder {
                margin-top: 31px;
                padding-left: 3px;
                box-sizing: border-box;

                .list {
                    font-size: 12px;
                    color: #666666;
                    margin-right: 33px;
                    position: relative;

                    .icon_collect {
                        width: 19px;
                        height: 19px;
                        margin-right: 6px;
                    }

                    .icon_PlaceOrder {
                        width: 22px;
                        height: 22px;
                        margin-right: 6px;
                    }
                }
            }
        }

        .swiper_box {
            width: 430px;
            // height: 68px;
            margin-top: 10px;

            // padding: 0px 20px;
            .goodsBox {
                width: 68px;
            }

            .goodsImg {
                width: 57px;

                img {
                    width: 57px;
                    height: 57px;
                }
            }

            .swiper-button-prev {
                top: 35px;
                left: 0px;
                width: 16px;
                height: 31px;
                color: #d0d0d0;
            }

            .swiper-button-next {
                top: 35px;
                right: 0px;
                width: 16px;
                height: 31px;
                color: #d0d0d0;
            }

            .swiper-button-prev:after,
            .swiper-button-next:after {
                content: none;
            }

            .swiper-slide {
                width: 68px !important;
                margin-left: 12px;
            }

            .swiper-container {
                padding: 0px 12px;
            }
        }

        .goodsContent_fr {
            margin-left: 20px;
            box-sizing: border-box;

            .goods_name {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                margin-top: 14px;
                line-height: 20px;
            }

            .preferential_price_box {
                width: 716px;
                height: 111px;
                margin-top: 17px;
                background: rgba(0, 0, 0, 0.06);
                padding: 24px 23px;
                box-sizing: border-box;

                .coupons {
                    margin-top: 22px;

                    .coupons_box {
                        .coupons_list {
                            height: 18px;
                            background: #ffdedf;
                            font-size: 12px;
                            color: #e1251b;
                            margin-right: 12px;
                            padding: 0px 10px 0px 12px;
                            box-sizing: border-box;
                            position: relative;
                            margin-top: 2px;
                            border-top: 1px solid #e1251b;
                            border-bottom: 1px solid #e1251b;
                            cursor: pointer;

                            .icon_fl {
                                width: 2px;
                                height: 16px;
                                position: absolute;
                                top: 0;
                                left: -1px;
                            }

                            .icon_fr {
                                width: 2px;
                                height: 16px;
                                position: absolute;
                                top: 0;
                                right: -1px;
                            }
                        }
                    }
                }

                .price_list {
                    .label {
                        width: 71px;
                        height: 14px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                    }

                    .price_box {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #e1251b;
                        line-height: 20px;

                        .price {
                            font-size: 24px;
                        }

                        .price_box_div {
                            margin-top: 3px;
                        }
                    }
                }
            }

            .specifications {
                margin-top: 24px;

                .list {
                    margin-bottom: 19px;

                    .table {
                        font-size: 12px;
                        color: #333333;
                        line-height: 24px;
                        margin-right: 39px;
                        white-space: nowrap;
                    }

                    ::v-deep.specifications_list {
                        .name {

                            height: 38px;
                            background: #ffffff;
                            border: 1px solid #dedede;
                            border-radius: 3px;
                            padding: 0px 30px;
                            box-sizing: border-box;
                            margin-right: 11px;
                            margin-bottom: 11px;
                        }

                        .el-button {
                            max-width: 600px;
                            margin-left: 0px;
                            margin-bottom: 11px;
                            margin-right: 11px;
                        }

                        .el-button:hover {
                            color: #e1251b;
                            border-color: #ffdedf;
                            background: #ffdedf;
                        }

                        .el-button:focus {
                            color: #e1251b;
                            border-color: #ffdedf;
                            background: #ffdedf;
                        }

                        .el-button--primary:hover {
                            color: white;
                            border-color: #e1251b;
                            background: #e1251b;
                        }

                        .el-button--primary:focus {
                            color: white;
                            border-color: #e1251b;
                            background: #e1251b;
                        }

                        .el-button--primary {
                            color: white;
                            border-color: #e1251b;
                            background: #e1251b;
                        }
                    }
                }
            }

            .safeguard {
                margin-top: 23px !important;
            }

            .payType {
                margin-top: 27px;

                .label {
                    font-size: 12px;
                    color: #333333;
                    line-height: 24px;
                    margin-right: 38px;
                }

                .payType_list {
                    .list {
                        font-size: 12px;
                        color: #333333;
                        margin-right: 37px;

                        img {
                            margin-right: 5px;
                        }

                        .wx_img {
                            width: 21px;
                            height: 21px;
                        }

                        .zfb_img {
                            width: 17px;
                            height: 17px;
                        }

                        .yl_img {
                            width: 27px;
                            height: 17px;
                        }

                        .safeguard_img {
                            width: 20px;
                            height: 17px;
                        }
                    }
                }
            }

            .goods_num_box {
                margin-top: 32px;

                .label {
                    font-size: 12px;
                    color: #333333;
                    margin-right: 36px;
                }
            }

            .btnBox {
                margin-top: 31px;

                .btn {
                    width: 160px;
                    height: 48px;
                    border-radius: 3px;
                    font-size: 18px;
                    color: #ffffff;
                }

                .buyBtn {
                    background: #e1251b;
                    margin-right: 20px;
                }

                .addCart {
                    color: #e1251b;
                    border: 1px solid #e1251b;
                }
            }
        }
    }

    .recommend_box {
        width: 100%;
        border: 1px solid #dedede;
        background: white;
        margin-top: 22px;

        .navBar {
            width: 1198px;
            height: 45px;
            background: #f4f4f4;
            box-sizing: border-box;

            // overflow: hidden;
            // overflow-x: scroll;
            .recommend_div {
                min-width: 109px;
                height: 45px;
                background: #e1251b;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
            }

            .active {
                color: #e62129 !important;
            }

            .list {
                min-width: 100px;
                height: 45px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                position: relative;
                margin-right: 0px;

                .line {
                    width: 52px;
                    height: 2px;
                    background: #e62129;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    margin-left: -26px;
                }
            }
        }

        .swiper_box {
            margin-top: 14px;

            .swiper {
                .swiper-slide {
                    //    width: 190px  !important
                }

                .goodsBox:hover {
                    .goodsName {
                        color: #e1251b !important;
                    }
                }

                .goodsBox {
                    width: 170px;
                    margin-left: 14px;
                    padding: 15px;
                    padding-top: 0px;
                    box-sizing: border-box;

                    .goodsImg {
                        img {
                            width: 166px;
                            height: 166px;
                        }
                    }

                    .goodsName {
                        width: 170px;
                        height: 33px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        margin: 15px 0;
                    }

                    .goodsPrice_box {
                        .price {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #e62129;
                            line-height: 20px;
                        }
                    }
                }
            }

            .swiper-button-prev {
                left: 0px;
                width: 24px;
                height: 38px;
                background: rgba(51, 51, 51, 0.4);
                border-radius: 0px 4px 4px 0px;
            }

            .swiper-button-next {
                right: 0px;
                width: 24px;
                height: 38px;
                background: rgba(51, 51, 51, 0.4);
                border-radius: 4px 0px 0px 4px;
            }

            .swiper-button-prev:after,
            .swiper-button-next:after {
                content: none;
            }
        }
    }

    .details_box {
        margin-top: 21px;
        margin-bottom: 42px;

        .fl {
            width: 200px;
            margin-right: 20px;
            background: white;
            border: 1px solid #dedede;

            .ranking {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin-top: 18px;
                margin-left: 18px;
                margin-bottom: 5px;
            }

            .goods_ranking_list:hover {
                .goodsName {
                    color: #e1251b !important;
                }
            }

            .goods_ranking_list:last-child {
                border-bottom: none !important;
            }

            .goods_ranking_list {
                width: 198px;
                padding: 20px 23px 27px 24px;
                box-sizing: border-box;
                border-bottom: 1px solid #dedede;

                .goods_img {
                    img {
                        width: 118px;
                        height: 118px;
                    }
                }

                .goodsName {
                    width: 153px;
                    font-size: 12px;
                    color: #333333;
                    margin-top: 18px;
                }

                .goodsPrice {
                    font-size: 12px;
                    color: #e1251b;
                    margin-top: 14px;
                }
            }
        }

        .fr {
            width: 978px;
            background: #fff;
            border: 1px solid #dedede;

            .navBerBox {
                width: 976px;
                height: 40px;
                background: #f4f4f4;

                .navActive {
                    background: #fff !important;
                }

                .list {
                    height: 40px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    padding: 0px 27px;
                    box-sizing: border-box;
                    line-height: 40px;
                    position: relative;

                    .line {
                        width: 100%;
                        height: 2px;
                        background: #e1251b;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            .goods_details_content {
                padding: 27px 40px;
                box-sizing: border-box;

                p {
                    font-size: 14px !important;
                    font-family: Microsoft YaHei;
                    display: block;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                }
            }

            .comment_box {}
        }
    }
}

.code_box {
    width: 94px;
    height: 98px;
    background: url('../../assets/img/goodsDetails/codeBg.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: -12px;
    top: 20px;

    img {
        width: 70px;
        height: 70px;
    }
}

.goods_sidebar {
    position: fixed;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
    background: white;
    z-index: 2;

    .list {
        width: 36px;
        height: 36px;
        background: #676767;
        border-radius: 3px;
        margin-bottom: 4px;
        position: relative;

        img {
            width: 20px;
            height: 20px;
        }

        .hintBox {
            height: 36px;
            background: #e1251b;
            border-radius: 3px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            padding: 10px;
            box-sizing: border-box;
            position: absolute;
            top: 0px;
            right: 40px;

            span {
                margin-left: 6px;
            }
        }

        .qrCode_box {
            width: 110px;
            height: 153px;
            background: #e1251b;
            border-radius: 3px;
            position: absolute;
            top: 0px;
            right: 40px;
            padding-top: 6px;

            img {
                width: 92px;
                height: 91px;
                margin-bottom: 4px;
            }

            span {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 16px;
                text-align: center;
            }
        }
    }
}

p {
    font-size: 14px !important;
}

.checks {
    width: 16px;
    height: 16px;
    margin-right: 14px;
}

.magnifyingGlass {
    position: relative;
    cursor: zoom-out;
    vertical-align: middle;

    .left_div {
        width: 430px;
        height: 430px;
        // position: relative;
    }

    .bigImgBox {
        width: 430px;
        height: 430px;

        /* 原图的显示 */
        .bigImgs {
            width: 385px;
            height: 385px;
            display: inline-block;
        }

        /* 层罩，通过定位将左上角定位到(0,0) */
        .top {
            width: 200px;
            height: 200px;
            background-color: lightcoral;
            opacity: 0.4;
            position: absolute;
            top: 0;
            left: 0;
        }

        /* 一个最高层层罩 */
        .maskTop {
            width: 430px;
            height: 430px;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
        }
    }

    /* 放大的图片，通过定位将左上角定位到(0,0) */
    .rightImg {
        display: inline-block;
        width: 785px;
        height: 785px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }

    /* 右边的区域图片放大空间 */
    .right {
        width: 430px;
        height: 430px;
        background: white;
        position: absolute;
        top: 0;
        left: 450px;
        overflow: hidden;
        z-index: 66;
        border: 1px solid red;
    }
}
</style>
<style >
.goods_details_content img {
    max-width: 896px;
}

.goodsDetailsBox .icon_left_right {
    width: 11px;
    height: 21px;
}

.goodsDetailsBox .swiper-container {
    width: 100%;
}

.goodsDetailsBox .swiper-slide {
    width: 160px !important;
    margin-left: 12px;
}
</style>